<template>
  <div class="prices-component">
    <b-overlay :show="loading">
      <div class="card">
        <div class="card-header">
          <div class="form-group w-100">
            <label>Selecciona el destino</label>
            <v-select
              v-model="destination_id"
              label="text"
              :clearable="false"
              :options="destinations"
              :reduce="value=>value.getAttr('id')"
              :get-option-label="value=>value.getAttr('name')"
              :multiple="false"
              :disabled="loading"
              placeholder="Seleccione un destino para configurar sus precios"
              @input="loadPrices"
            />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <!--                <tr>
          <td />
          <td
            colspan="9"
          >
            <p class="m-0 text-center">
              Horas
            </p>
          </td>
        </tr>-->
                <tr>
                  <td class="bg-white">
                    Tipo de modelo
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      1
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      2
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      3
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      4
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      5
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      6
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      7
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      8
                    </p>
                  </td>
                  <td>
                    <p class="m-0 text-center">
                      9
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(modelType) of modelTypes"
                  :key="modelType.getAttr('id')"
                >
                  <td>
                    {{ modelType.getAttr('name') }}
                  </td>
                  <td
                    v-for="(_, index) of models[modelType.getAttr('id')]"
                    :key="index"
                  >
                    <div class="form-group">
                      <label>Precio neto</label>
                      <input
                        v-model="models[modelType.getAttr('id')][index].net_price"
                        type="number"
                        class="form-control input-price"
                      >
                    </div>
                    <div class="form-group">
                      <label>Precio público</label>
                      <input
                        v-model="models[modelType.getAttr('id')][index].public_price"
                        type="number"
                        class="form-control input-price"
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <button
            class="btn btn-primary"
            @click="save"
          >
            Guardar
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Price from '@/dc-it/models/Price'
import { BOverlay } from 'bootstrap-vue'
import Destination from '@/dc-it/models/Destination'
import ModelType from '@/dc-it/models/ModelType'
import vSelect from 'vue-select'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'PricesView',
  components: { BOverlay, vSelect },
  data() {
    return {
      model: Price,
      loading: false,
      destinations: [],
      modelTypes: [],
      destination_id: null,
      // prices: [],
      models: {},
    }
  },
  mounted() {
    this.loading = true
    const promises = [
      Destination.where('id', '>', 0).get().then(destinations => {
        this.destinations = destinations
      }),
      ModelType.where('id', '>', 0).get().then(modelTypes => {
        this.modelTypes = modelTypes

        for (let i = 0; i < this.modelTypes.length; i += 1) {
          this.models[`${this.modelTypes[i].getAttr('id')}`] = []
          for (let j = 0; j < 9; j += 1) {
            this.models[`${this.modelTypes[i].getAttr('id')}`].push({
              model_type_id: this.modelTypes[i].getAttr('id'),
              destination_id: this.destination_id,
              modelType: this.modelTypes[i],
              base_hour: j + 1,
              net_price: null,
              public_price: null,
              id: null,
            })
          }
        }
      }),
    ]

    Promise.all(promises).then(() => {
      this.loading = false
    })
  },
  methods: {
    save() {
      if (!this.destination_id) return
      for (let i = 0; i < this.modelTypes.length; i += 1) {
        const modelType = this.modelTypes[i]
        for (let j = 0; j < this.models[modelType.getAttr('id')].length; j += 1) {
          if (!this.models[modelType.getAttr('id')][j].public_price || !this.models[modelType.getAttr('id')][j].net_price) {
            this.$bvToast.toast('Debe completar todos los precios', {
              title: 'Error',
              variant: 'danger',
              solid: false,
            })
            return
          }
        }
      }

      this.loading = true
      GeneralService.getInstance().save('prices/massive', { prices: this.models }).then(response => {
        if (response.result === 'success') {
          this.$bvToast.toast('Precios guardados correctamente', {
            title: 'Éxito',
            variant: 'success',
            solid: false,
          })
          this.loadPrices()
        } else {
          this.$bvToast.toast('Error al guardar los precios', {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
          this.loading = false
        }
      })
    },
    loadPrices() {
      if (!this.destination_id) return

      this.modelTypes.forEach(modelType => {
        for (let j = 0; j < this.models[modelType.getAttr('id')].length; j += 1) {
          this.models[modelType.getAttr('id')][j].destination_id = this.destination_id
          this.models[modelType.getAttr('id')][j].id = null
          this.models[modelType.getAttr('id')][j].net_price = null
          this.models[modelType.getAttr('id')][j].public_price = null
        }
      })

      this.loading = true
      Price.where('destination_id', '=', this.destination_id).get().then(prices => {
        prices.forEach(price => {
          const model = this.models[`${price.getAttr('model_type_id')}`]
          const index = model.findIndex(currentPrice => currentPrice.base_hour.toString() === price.getAttr('base_hour').toString())

          if (index !== -1) {
            this.models[`${price.getAttr('model_type_id')}`][index].id = price.getAttr('id')
            this.models[`${price.getAttr('model_type_id')}`][index].net_price = price.getAttr('net_price', false)
            this.models[`${price.getAttr('model_type_id')}`][index].public_price = price.getAttr('public_price', false)
          }
        })

        this.loading = false
      })
    }
    ,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.prices-component {
  tr > th:first-child, tr > td:first-child {
    position: sticky;
    left: 0;
  }

  tr:nth-child(odd) > td:first-child {
    background: #fafafc;
  }

  tr:nth-child(even) > td:first-child {
    background: #fff;
  }

  .input-price {
    width: 150px;
  }
}

</style>
