import Relation from '@/dc-it/types/Relation'
import Destination from '@/dc-it/models/Destination'
import ModelType from '@/dc-it/models/ModelType'
import helpers from '@/dc-it/services/helpers'
import Field from '../types/Field'
import Model from './Model'

export default class Price extends Model {
    endpoint = 'prices';

    related = ['destination', 'model_type'];

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('destination', 'Destino', Destination, 'name'),
      new Relation('model_type', 'Tipo de modelo', ModelType, 'name'),
      new Field('public_price', 'Precio público', 'number').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('net_price', 'Precio neto', 'number').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('base_hour', 'Horas base'),
    ];

    clone = () => Price;
}
